html {
  font-family: Lato, Arial, sans-serif;
  font-size: 14px;
}

.App {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.login-message {
  padding: 8px 6px;
}

.panel-danger.errors {
  display: none;
}

/** Decrease spacing between handicap checkboxes */
fieldset#root_austattung .field-boolean {
  padding: 0;
}

.dnd-sortable-top {
  border-top: 3px solid red;
}

/* .dnd-sortable-top > div {
  transform: translateY(45px);
  transition: transform 0.3s;
} */

.dnd-sortable-bottom {
  border-bottom: 3px solid red;
}

/* .dnd-sortable-bottom > div {
  transform: translateY(-45px);
  transition: transform 0.3s;
} */

@import '~ol/ol.css';

.rs-map {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.rs-zooms-bar {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 2rem;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.199);
  right: 20px;
  top: 10px;
  color: #515151;

  .rs-zoom-in,
  .rs-zoom-out {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    z-index: 50;
    padding: 5px;
    margin: 5px 0;
    cursor: pointer;
    border: 1px solid #515151;

    &:focus {
      outline: none;
    }

    &:disabled {
      opacity: 0.5;
      cursor: auto;
    }
  }

  .rs-zoomslider-wrapper {
    position: relative;
    height: 205px;
    width: 40px;
    top: 0;
    left: 0;
    z-index: 40;

    .ol-zoomslider {
      top: 5px;
      left: 0;
      border: none;
      height: 90%;
      background-color: unset;

      .ol-zoomslider-thumb {
        position: absolute;
        background: #fff;
        border: 0.5px solid #fff;
        display: flex;
        align-items: center;
        border-radius: 50%;
        z-index: 10;
        height: 20px;
        width: 20px;
        cursor: pointer;
        margin: 0 10px;
        left: 0;

        &:focus {
          outline: none;
        }
      }

      .ol-zoomslider-thumb::before {
        content: '';
        height: 100%;
        width: 100%;
        background-color: #515151;
        border-radius: 50%;
      }
    }

    .ol-zoomslider::after {
      content: '';
      margin: 8px 17px 0;
      width: 2px;
      height: 94%;
      background-color: #515151;
      position: relative;
      top: 0;
      left: 0;
      display: block;
    }
  }
}

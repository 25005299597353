.ms-list {
  border-collapse: collapse;
  overflow-y: auto;

  th,
  td {
    border: 1px solid #e3e3e3;
    padding: 5px;
    cursor: pointer;
  }

  tr {
    &:hover {
      background-color: #e3e3e3;
    }
  }

  td {
    &.active {
      font-weight: bold;

      svg {
        stroke-width: 2;
      }
    }
  }
}

.ms-list-loading {
  background-color: rgba(255, 255, 255, 0.4);
  z-index: 1;
}
